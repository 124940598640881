<template>
<div>
    <img :src="myFiles" class="d-none" alt="">
    <file-pond :value="myFiles" name="upload" ref="pond"  label-idle="Bấm vào để chọn tệp..."  v-bind:allow-multiple="false" :server="server" v-bind:files="myFiles" v-on:init="handleFilePondInit" v-on:processfile="handleProcessFile" style="cursor: pointer" />
</div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import {httpPreview} from "../../../../_utils/httpPreview";
import {httpUpload} from "../../../../_utils/httpUpload";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFilePoster);

export default {
    name: 'a-file',
    props: {
        value: String,
    },
    components: {
        FilePond,
    },
    data: function () {
        return {
            myFiles: "",
            server: {
                process: {
                    url: httpUpload + `core/connector/php/connector.php?command=FileUpload&currentFolder=&hash=523cac8c2c3bbeda&responseType=json&type=Files`,
                    method: 'POST',
                    withCredentials: false,
                    onload: (response) => {
                        let data = JSON.parse(response);
                        this.$emit('input', data.currentFolder.url + data.fileName);
                        return data.currentFolder.url + data.fileName;
                    },
                    ondata: (formData) => {
                        // formData.append('name', 'thumbnail');
                        return formData;
                    },
                },
                // eslint-disable-next-line no-unused-vars
                load: (url, load, error, progress, abort, headers) => {
                    if (!url.startsWith('http')) {
                        url = httpPreview + url;
                    }
                    console.log(url, "check url")
                    fetch(url).then((res) => {
                        return res.blob();
                    }).then(load);
                    return {
                        abort: () => {
                            abort();
                        },
                    };
                },
            },
        };
    },
    watch: {
        'myFiles': {
            handler: function () {

            },
            deep: true,
        },
    },
    methods: {
        handleFilePondInit: function () {
            console.log('FilePond has initialized');
            // FilePond instance methods are available on `this.$refs.pond`
        },
        handleProcessFile: function (error, file) {
            if (error == null) {
                this.$emit('input', file.serverId);
            }
        },
        // async getResponseUpload(response) {
        //     response;
        //     let file = this.$refs.pond.getFile(0);
        //     let serverId = file.getMetadata('serverId');
        //     console.log('getResponseUpload', file, serverId, file.serverId);
        // },

    },
    created() {
        if (this.value.length > 0) {
            this.myFiles = [{
                source: this.value,
                options: {
                    type: 'local'
                },
            }];
        }
    }
};
</script>

<style lang="scss">
.filepond--image-preview-overlay-success {
    display: none !important;
}

.filepond--file-status {
    display: none !important;
}

.filepond--credits {
    display: none !important;
}
</style>
