<template>
<div>
    <img :src="myFiles" class="d-none" alt="">
    <file-pond name="upload" ref="pond" label-idle="Chọn ảnh/tài liệu" data-max-files="10" v-bind:allow-multiple="true" :server="server" v-bind:files="myFiles" v-on:init="handleFilePondInit" v-on:processfile="handleProcessFile" v-on:removefile="changeListFiles" @error="handleFilePondError" />
</div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {httpUpload} from "../../../../_utils/httpUpload";
import { httpPreview } from '../../../../_utils/httpPreview';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
    name: 'surveys-file',
    props: {
        value: Array,
    },
    components: {
        FilePond,
    },
    data: function () {
        return {
            myFiles: [],
            currentFiles: [],
            server: {
                process: {
                    url: httpUpload + `core/connector/php/connector.php?command=FileUpload&currentFolder=&hash=523cac8c2c3bbeda&responseType=json&type=Files`,
                    method: 'POST',
                    withCredentials: false,
                    onload: (response) => {
                        let data = JSON.parse(response);
                        return data.currentFolder.url + data.fileName;
                    },
                    ondata: (formData) => {
                        return formData;
                    },
                },
                // eslint-disable-next-line no-unused-vars
                load: (url, load, error, progress, abort, headers) => {
                    if (!url.startsWith('http')) {
                        url = httpPreview + url;
                    }
                    fetch(url).then((res) => {
                        return res.blob();
                    }).then(load);
                    return {
                        abort: () => {
                            abort();
                        },
                    };
                },
            },
        };
    },
    watch: {
        'myFiles': {
            handler: function () {},
            deep: true,
        },
        value: {
            handler: function () {},
            deep: true,
        },
    },
    methods: {
        handleFilePondInit: function () {
            console.log('FilePond has initialized');
            // FilePond instance methods are available on `this.$refs.pond`
        },
        async getResponseUpload(response) {
            response;
            console.log({
                text: 'getResponseUpload',
                response
            });
            let file = this.$refs.pond.getFile(0);

            let serverId = file.getMetadata('serverId');
            console.log('getResponseUpload', file, serverId, file.serverId);
        },
        handleProcessFile: function (error, file) {
            if (error == null) {
                console.log('handleProcessFile', file);
                this.currentFiles.push(file.serverId);
                this.$emit('input', this.currentFiles);
            }
        },
        handleFilePondError(error, file, status) {
            console.log('FilePond Error: ', error, file, status);
        },
        changeListFiles() {
            console.log(this.$refs.pond.getFiles());
            this.currentFiles = [];
            this.$refs.pond.getFiles().map(e => {
                this.currentFiles.push(e.serverId);
            });
            this.$emit('input', this.currentFiles);
        },

    },
    created() {
        setTimeout(() => {
            if (this.value.length > 0) {
                this.currentFiles = this.value;
                this.value.map(e => {
                    this.myFiles.push({
                        source: e,
                        options: {
                            type: 'local'
                        },
                    });
                });
            }
        }, 1000);
    },
};
</script>

<style lang="scss">
.filepond--image-preview-overlay-success {
    display: none !important;
}

.filepond--file-status {
    display: none !important;
}

.filepond--credits {
    display: none !important;
}
</style>
